export const MESSAGING_ENABLED_USERS = [
  'test@client.com',
  'demo@passentry.com',
  'emily@passentry.com',
  'lukas@passentry.com',
  'team@passentry.com',
  'Nicolae.popescu@passentry.com',
  'Josie.kinnear@passesntry.com',
  'Daniel.Esparza@passentry.com',
  'veerendra.shankara@network.global',
  'sivani.yelamanchili@network.global',
];
