import appsignal from '../../appsignal';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import PassTemplatesPage from './PassTemplatesPage';
import PassTemplatesListContext, {
  initialPassTemplates,
} from '../../store/client/PassTemplatesListContext';

import placeholderBanner from '../../assets/images/banner.png';
import placeholderIcon from '../../assets/images/icon.png';
import placeholderLogo from '../../assets/images/logo.png';
import placeholderMonochromeBadge from '../../assets/images/monochrome-badge.png';
import { HttpContext } from '../../context/HttpContext';
import { MeContext } from '../../context/MeContext';
import { getErrorResponsePayload } from '../../utils/ajax';
import CustomToast from '../../common/CustomToast';
import { useToast } from '@chakra-ui/toast';
import { useNavigate } from 'react-router';
import { debounce } from 'lodash';

function normalizedData(data) {
  if (data?.length > 0) {
    return data.map((item) => {
      return {
        ...item,
        bodyFontColor: item.text_color,
        templateName: item.name,
        passBackgroundColor: item.background_color,
        titleFontColor: item.label_color,
        uuid: item.uuid,
        templateEntity: item.entity_id,
        eventId: item.event_id,
        passType: item.template_type,
        headerOneId: item.header.header_one.id,
        headerOneLabel: item.header.header_one.label,
        headerOneValue: item.header.header_one.default_value,
        primaryOneId: item.primary.primary_one.id,
        primaryOneLabel: item.primary.primary_one.label,
        primaryOneValue: item.primary.primary_one.default_value,
        secOneId: item.secondary.sec_one.id,
        secOneLabel: item.secondary.sec_one.label,
        secOneValue: item.secondary.sec_one.default_value,
        secTwoId: item.secondary.sec_two.id,
        secTwoLabel: item.secondary.sec_two.label,
        secTwoValue: item.secondary.sec_two.default_value,
        secThreeId: item.secondary.sec_three.id,
        secThreeLabel: item.secondary.sec_three.label,
        secThreeValue: item.secondary.sec_three.default_value,
        description: item.description,
        auxOneId: item.auxiliary.aux_one.id,
        auxOneLabel: item.auxiliary.aux_one.label,
        auxOneValue: item.auxiliary.aux_one.default_value,
        auxTwoId: item.auxiliary.aux_two.id,
        auxTwoLabel: item.auxiliary.aux_two.label,
        auxTwoValue: item.auxiliary.aux_two.default_value,
        auxThreeId: item.auxiliary.aux_three.id,
        auxThreeLabel: item.auxiliary.aux_three.label,
        auxThreeValue: item.auxiliary.aux_three.default_value,
        backOne: item.back_fields.back_one.label,
        backTwo: item.back_fields.back_two.label,
        backThree: item.back_fields.back_three.label,
        backFour: item.back_fields.back_four.label,
        backFive: item.back_fields.back_five.label,
        backOneValue: item.back_fields.back_one.default_value,
        backTwoValue: item.back_fields.back_two.default_value,
        backThreeValue: item.back_fields.back_three.default_value,
        backFourValue: item.back_fields.back_four.default_value,
        backFiveValue: item.back_fields.back_five.default_value,
        inList: true,
        cutoutBg: '#E8E7E7',
        editUrl: `/pass-templates/edit/${item.uuid}`,
        logoImage: item.logo_image ?? placeholderLogo,
        iconLogo: item.icon_image ?? placeholderIcon,
        bannerImage: item.banner_image ?? placeholderBanner,
        googleBannerImage: item.google_banner_image,
        thumbnailImage: !item.thumbnail_image
          ? item.template_type === 'generic'
            ? null
            : placeholderIcon
          : item.thumbnail_image,
        monoChromeBadge: item.monochrome_badge ?? placeholderMonochromeBadge,
        appleAppLink: item.apple_app_link,
        stamp_count: item.stamp_count,
        stamp_short_key: item.stamp_short_key,
        stamp_long_key: item.stamp_long_key,
        stamp_zero_message: item.stamp_zero_message,
        stamp_one_message: item.stamp_one_message,
        stamp_two_message: item.stamp_two_message,
        stamp_three_message: item.stamp_three_message,
        stamp_four_message: item.stamp_four_message,
        stamp_five_message: item.stamp_five_message,
        stamp_six_message: item.stamp_six_message,
        stamp_seven_message: item.stamp_seven_message,
        stamp_eight_message: item.stamp_eight_message,
        stamp_nine_message: item.stamp_nine_message,
        stamp_ten_message: item.stamp_ten_message,
        stamp_eleven_message: item.stamp_eleven_message,
        stamp_twelve_message: item.stamp_twelve_message,
        stamp_zero_short_value: item.stamp_zero_short_value,
        stamp_one_short_value: item.stamp_one_short_value,
        stamp_two_short_value: item.stamp_two_short_value,
        stamp_three_short_value: item.stamp_three_short_value,
        stamp_four_short_value: item.stamp_four_short_value,
        stamp_five_short_value: item.stamp_five_short_value,
        stamp_six_short_value: item.stamp_six_short_value,
        stamp_seven_short_value: item.stamp_seven_short_value,
        stamp_eight_short_value: item.stamp_eight_short_value,
        stamp_nine_short_value: item.stamp_nine_short_value,
        stamp_ten_short_value: item.stamp_ten_short_value,
        stamp_eleven_short_value: item.stamp_eleven_short_value,
        stamp_twelve_short_value: item.stamp_twelve_short_value,
        stamp_zero_long_value: item.stamp_zero_long_value,
        stamp_one_long_value: item.stamp_one_long_value,
        stamp_two_long_value: item.stamp_two_long_value,
        stamp_three_long_value: item.stamp_three_long_value,
        stamp_four_long_value: item.stamp_four_long_value,
        stamp_five_long_value: item.stamp_five_long_value,
        stamp_six_long_value: item.stamp_six_long_value,
        stamp_seven_long_value: item.stamp_seven_long_value,
        stamp_eight_long_value: item.stamp_eight_long_value,
        stamp_nine_long_value: item.stamp_nine_long_value,
        stamp_ten_long_value: item.stamp_ten_long_value,
        stamp_eleven_long_value: item.stamp_eleven_long_value,
        stamp_twelve_long_value: item.stamp_twelve_long_value,
        stamp_zero_image: item.stamp_zero_image,
        stamp_one_image: item.stamp_one_image,
        stamp_two_image: item.stamp_two_image,
        stamp_three_image: item.stamp_three_image,
        stamp_four_image: item.stamp_four_image,
        stamp_five_image: item.stamp_five_image,
        stamp_six_image: item.stamp_six_image,
        stamp_seven_image: item.stamp_seven_image,
        stamp_eight_image: item.stamp_eight_image,
        stamp_nine_image: item.stamp_nine_image,
        stamp_ten_image: item.stamp_ten_image,
        stamp_eleven_image: item.stamp_eleven_image,
        stamp_twelve_image: item.stamp_twelve_image,
        locations: item.locations,
        beacons: item.beacons,
      };
    });
  }
  return [];
}

function PassTemplatesClientContent() {
  const passTemplatesListCtx = useContext(PassTemplatesListContext);
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);
  const meCtx = useContext(MeContext);
  const isOrganisationActive = meCtx?.isOrganisationActive() ?? true;
  const isSetUpConfirmed = meCtx?.isSetUpConfirmed() ?? true;
  const {
    passTemplates,
    passTemplates: { items, hasMore, status, pageIndex, pageSize, items: prev },
    incrementPageIndex,
    resetPageIndex,
  } = useContext(PassTemplatesListContext);
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [inputSearch, setInputSearch] = useState('');
  const { filterEntity } = meCtx.state;

  useEffect(() => {
    const queryPassType =
      passTemplatesListCtx.filterValues.passType !== ''
        ? '&pass_type=' + passTemplatesListCtx.filterValues.passType
        : '';
    const queryEvent =
      passTemplatesListCtx.filterValues.event !== ''
        ? '&event=' + passTemplatesListCtx.filterValues.event
        : '';
    const querySearch = inputSearch !== '' ? '&search=' + inputSearch : '';
    const query = `${queryPassType}${queryEvent}${querySearch}`;
    setQuery(query);
    resetPageIndex();
  }, [passTemplatesListCtx.filterValues, inputSearch]);

  useEffect(() => {
    passTemplatesListCtx.updatePassTemplates(initialPassTemplates);
    resetPageIndex();
  }, [filterEntity]);

  useEffect(() => {
    if (!isOrganisationActive || !isSetUpConfirmed) {
      return;
    }
    const getData = async () => {
      passTemplatesListCtx.updatePassTemplates({
        ...passTemplates,
        loading: true,
        status: 'pending',
      });
      try {
        let response;
        const startRow = pageSize * pageIndex;
        if (filterEntity) {
          response = await authAxios.get(
            `entities/${filterEntity.uuid}/passes/templates?limit=${pageSize}&offset=${startRow}${query}`
          );
        } else {
          response = await authAxios.get(
            `passes/templates?limit=${pageSize}&offset=${startRow}${query}`
          );
        }
        const total = response?.data?.meta?.total ?? 0;
        const data = response.data?.data;
        const pageCount = Math.ceil(total / pageSize);
        const hasMore =
          pageCount === -1 ? pageIndex >= pageSize : pageIndex < pageCount - 1;
        passTemplatesListCtx.updatePassTemplates({
          ...passTemplates,
          items: pageIndex > 0 ? prev.concat(data) : data,
          total,
          hasMore,
          pageCount,
          loading: false,
          status: 'resolved',
        });
      } catch (onError) {
        appsignal.sendError(onError);
        console.log(onError);
        const { message, code } = getErrorResponsePayload(onError);
        code !== 401 &&
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={message ? message : `Something went wrong`}
                description={!message && 'Please try again later'}
                onClose={props.onClose}
              />
            ),
          });
        navigate('/pass-templates', { replace: true });
      }
    };

    // Add a debounce of 300 milliseconds
    const debouncedGetData = debounce(getData, 300);
    debouncedGetData();

    // Cleanup function to clear the debounce timer
    return () => debouncedGetData.cancel();
  }, [
    authAxios,
    query,
    pageIndex,
    pageSize,
    isOrganisationActive,
    isSetUpConfirmed,
    filterEntity,
  ]);

  const formattedData = useMemo(() => normalizedData(items), [items]);
  const filtersApplied =
    passTemplatesListCtx.filterValues.passType !== '' ||
    passTemplatesListCtx.filterValues.event !== '' ||
    inputSearch !== '';

  return (
    <PassTemplatesPage
      items={formattedData}
      loading={status === 'idle' || status === 'pending'}
      hasMore={hasMore}
      loadMore={incrementPageIndex}
      filtersApplied={filtersApplied}
      setInputSearch={setInputSearch}
    />
  );
}

export default PassTemplatesClientContent;
