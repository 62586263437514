import { useContext, useEffect, useState, useRef } from 'react';
import {
  useColorModeValue,
  Text,
  useToast,
  useDisclosure,
  Grid,
  VStack,
  FormControl,
  Checkbox,
  Collapse,
  FormLabel,
  Input,
  Box,
  GridItem,
  Stack,
  Button,
  Flex,
  Spinner,
  Center,
  Switch,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { MeContext } from '../../context/MeContext';
import { useForm } from 'react-hook-form';
import { HttpContext } from '../../context/HttpContext';
import placeholderIcon from '../../assets/images/icon.png';
import appsignal from '../../appsignal';
import { getErrorResponsePayload } from '../../utils/ajax';
import {
  CustomReactSelect,
  formatOptionLabelFilterGroupTag,
  formatOptionLabelFilterPassTemplate,
} from '../../components/common/CustomReactSelect';
import { TextareaAutosize } from '../../components/common/TextareaAutosize';
import PreviewPassNotificationsApple from '../../components/client/pass-templates/PreviewPassNotificationsApple';
import PreviewPassNotificationsAndroid from '../../components/client/pass-templates/PreviewPassNotificationsAndroid';
import CustomToast from '../../common/CustomToast';
import MessagingConfirmationModal from '../../components/modals/MessagingConfirmationModal';
import MessageContext from '../../store/client/MessageContext';

const MessagesCreatePage = () => {
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);
  const { isOpen: isOpenPassTemplate, onToggle: onTogglePassTemplate } =
    useDisclosure();
  const { isOpen: isOpenGroupTag, onToggle: onToggleGroupTag } =
    useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [passTemplatesList, setPassTemplatesList] = useState([]);
  const [selectedPassTemplate, setSelectedPassTemplate] = useState('');
  const [groupTagsList, setGroupTagsList] = useState([]);
  const [selectedGroupTag, setSelectedGroupTag] = useState('');
  const [messageHeader, setMessageHeader] = useState('');
  const [iconLogo, setIconLogo] = useState('');
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const meCtx = useContext(MeContext);
  const { filterEntity, entities } = meCtx.state;
  const messageCtx = useContext(MessageContext);
  const { messageText } = messageCtx;
  const [filterEntityValue, setFilterEntityValue] = useState('');
  const [totalPasses, setTotalPasses] = useState(0);
  const [isCsvMode, setIsCsvMode] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const {
    isOpen: isCsvModalOpen,
    onOpen: onCsvModalOpen,
    onClose: onCsvModalClose,
  } = useDisclosure();
  const {
    isOpen: isRegularModalOpen,
    onOpen: onRegularModalOpen,
    onClose: onRegularModalClose,
  } = useDisclosure();
  const cancelRef = useRef();
  const fileInputRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const handleCheckboxChange = (toggleFunction, callbackFunction) => {
    toggleFunction();
    callbackFunction();
  };

  const previewData = {
    iconLogo: iconLogo || placeholderIcon,
    notificationHeader: messageHeader || 'Notification header',
    messageText: messageText,
    smallerDisplay: true,
  };

  const messageData = {
    notification: {
      message: messageText,
    },
  };

  const handleRegularSend = async () => {
    onRegularModalClose(); // Close the regular confirmation dialog
    try {
      let entityParam = filterEntity?.uuid
        ? `entityId=${filterEntity.uuid}`
        : `entityId=${entities[0].uuid}`;
      let passTemplateParam = selectedPassTemplate?.value?.uuid
        ? `${entityParam ? '&' : ''}passTemplateId=${selectedPassTemplate?.value?.uuid}`
        : '';
      let groupTagParam = selectedGroupTag?.label
        ? `${entityParam || passTemplateParam ? '&' : ''}groupTag=${selectedGroupTag?.label}`
        : '';

      await authAxios.post(
        `api/v1/messaging/wallet?${entityParam}${passTemplateParam}${groupTagParam}`,
        messageData
      );
      toast({
        render: ({ onClose }) => (
          <CustomToast
            status="success"
            title={`${totalPasses} message${totalPasses === 1 ? ' ' : 's'} sent`}
            description="Pass holders will receive the message shortly"
            onClose={onClose}
          />
        ),
      });
    } catch (error) {
      appsignal.sendError(error);
      toast({
        render: ({ onClose }) => (
          <CustomToast
            status="error"
            title="Something went wrong"
            description="Please try again later."
            onClose={onClose}
          />
        ),
      });
    }
  };

  const onSubmitWrapper = (data) => {
    if (isCsvMode) {
      onCsvModalOpen(); // Open CSV confirmation dialog
    } else {
      onRegularModalOpen(); // Open regular confirmation dialog
    }
  };

  useEffect(() => {
    if (selectedPassTemplate) {
      setIconLogo(selectedPassTemplate.value.icon_image);
      setMessageHeader(selectedPassTemplate.value.notification_header);
    } else {
      setIconLogo('');
      setMessageHeader('');
    }
  }, [selectedPassTemplate]);

  useEffect(() => {
    const getTotalPasses = async () => {
      try {
        if (!filterEntityValue) {
          return;
        }
        setIsLoading(true);
        let passTemplateParam = selectedPassTemplate?.value?.uuid
          ? `&passTemplateName=${encodeURIComponent(selectedPassTemplate.value.name)}`
          : '';
        let groupTagParam = selectedGroupTag?.label
          ? `&groupTag=${encodeURIComponent(selectedGroupTag.label)}`
          : '';
        const activePassesCount = await authAxios.get(
          `api/v1/entities/${filterEntityValue.uuid}/passes?fields=status&status=active${passTemplateParam}${groupTagParam}`
        );
        const totalActivePasses = activePassesCount.data?.meta?.totalCount ?? 0;
        const issuedPassesCount = await authAxios.get(
          `api/v1/entities/${filterEntityValue.uuid}/passes?fields=status&status=issued${passTemplateParam}${groupTagParam}`
        );
        const totalIssuedPasses = issuedPassesCount.data?.meta?.totalCount ?? 0;
        const total = totalActivePasses + totalIssuedPasses;
        setTotalPasses(total);
        setIsLoading(false);
      } catch (error) {
        appsignal.sendError(error);
        console.log(error);
        setIsLoading(false);
      }
    };
    getTotalPasses();
  }, [authAxios, filterEntityValue, selectedPassTemplate, selectedGroupTag]);

  useEffect(() => {
    setSelectedPassTemplate('');
    const getData = async () => {
      try {
        if (!filterEntityValue) {
          return;
        }
        let response;
        response = await authAxios.get(
          `entities/${filterEntityValue.uuid}/passes/templates`
        );

        const data = response?.data?.data;
        data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        let templates = [];
        if (data.length === 0) {
          setPassTemplatesList([]);
          return;
        }
        data.forEach((item) => {
          templates.push({
            label: item.name,
            value: item,
          });
        });
        setPassTemplatesList(templates);
        // if no filter is set always return to All pass templates default
      } catch (error) {
        appsignal.sendError(error);
        console.log(error);
        const { message, code } = getErrorResponsePayload(error);
        code !== 401 &&
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={message ? message : `Something went wrong`}
                description={!message && 'Please try again later'}
                onClose={props.onClose}
              />
            ),
          });
      }
    };

    getData();

    return () => {
      setPassTemplatesList([]);
    };
  }, [authAxios, filterEntityValue]);

  // fetch all group tags for select group tags dropdown
  useEffect(() => {
    setSelectedGroupTag('');
    const getGroupTags = async () => {
      try {
        if (!filterEntityValue) {
          return;
        }
        let response;
        response = await authAxios.get(
          `api/v1/entities/${filterEntityValue.uuid}/passes?fields=groupTag`
        );

        const data = response.data?.data;
        const groupTags = data.map((item) => {
          if (item.attributes.groupTag) {
            return {
              label: item.attributes.groupTag,
            };
          } else {
            return undefined;
          }
        });
        const filteredGroupTags = groupTags.filter(
          (item) => item !== undefined
        );
        const uniqueGroupTags = Array.from(
          new Set(filteredGroupTags.map((a) => a.label))
        ).map((label) => {
          return filteredGroupTags.find((a) => a.label === label);
        });
        setGroupTagsList(uniqueGroupTags);
      } catch (error) {
        appsignal.sendError(error);
        console.log(error);
      }
    };
    getGroupTags();
  }, [authAxios, filterEntityValue]);

  useEffect(() => {
    if (entities) {
      setFilterEntityValue(entities.length > 1 ? filterEntity : entities[0]);
    }
  }, [entities, filterEntity]);

  useEffect(() => {
    const resetTemplateAndTag = (
      templateKey,
      setTemplate,
      isOpen,
      onToggle
    ) => {
      reset({ [templateKey]: '' });
      setTemplate('');
      isOpen && onToggle();
    };

    resetTemplateAndTag(
      'passTemplate',
      setSelectedPassTemplate,
      isOpenPassTemplate,
      onTogglePassTemplate
    );
    resetTemplateAndTag(
      'groupTag',
      setSelectedGroupTag,
      isOpenGroupTag,
      onToggleGroupTag
    );
  }, [filterEntityValue, reset]);

  useEffect(() => {
    if (!filterEntityValue) {
      setIsCsvMode(false);
      setSelectedFile(null);
      // Reset message preview
      setIconLogo('');
      setMessageHeader('');
      messageCtx.updateMessageText('');
    }
  }, [filterEntityValue, messageCtx]);

  const handleCsvToggle = () => {
    setIsCsvMode(!isCsvMode);
    setSelectedFile(null);
    // Reset message preview when toggling CSV mode
    if (!isCsvMode) {
      setIconLogo('');
      setMessageHeader('');
      messageCtx.updateMessageText('');
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const resetFileInput = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleCsvUpload = async () => {
    onCsvModalClose();
    setIsProcessing(true);
    try {
      const formData = new FormData();
      formData.append('csv_file', selectedFile);

      await authAxios.post(
        `api/v1/messaging/wallet?entityId=${filterEntity.uuid}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      toast({
        render: ({ onClose }) => (
          <CustomToast
            status="success"
            title="CSV uploaded successfully"
            description="Messages will be sent to pass holders"
            onClose={onClose}
          />
        ),
      });

      resetFileInput();
    } catch (error) {
      console.error('CSV upload failed:', error);
      toast({
        render: ({ onClose }) => (
          <CustomToast
            status="error"
            title="CSV upload failed"
            description="Please try again later"
            onClose={onClose}
          />
        ),
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const renderCsvUploadForm = () => {
    return (
      <FormControl>
        <FormLabel
          pl="5px"
          pt="12px"
          pb="6px"
          textStyle="bodyRegular"
          color="secondaryDark"
        >
          Upload CSV file
        </FormLabel>
        <Button
          as="label"
          htmlFor="csv-upload"
          cursor="pointer"
          variant="outline"
          size="md"
          width="full"
        >
          {selectedFile ? selectedFile.name : 'Choose File'}
          <input
            id="csv-upload"
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            ref={fileInputRef}
          />
        </Button>
      </FormControl>
    );
  };

  const isEntitySelected = !!filterEntityValue;
  const isSendDisabled = isCsvMode
    ? !selectedFile
    : !filterEntityValue || totalPasses === 0;

  return (
    <>
      {!isLoading ? (
        <>
          <form onSubmit={handleSubmit(onSubmitWrapper)}>
            <Grid
              templateColumns={{
                base: '100%',
                xl: '40% 60%',
              }}
              gap={6}
              mr={{ base: '0px', md: '30px' }}
            >
              <Box
                borderRadius="15px"
                padding="20px 30px"
                minW={{ base: 'none', md: 'fit-content' }}
                top="20px"
                width="100%"
                background={modeBoxBg}
                height="fit-content"
              >
                <VStack align="flex-start" spacing={4}>
                  <Flex
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text size="lg" textStyle="bodyBold" color="secondaryDark">
                      {isCsvMode ? 'CSV Upload' : 'Filter Passes'}
                    </Text>
                    <Flex alignItems="center">
                      <Text mr={2} fontSize="sm">
                        CSV
                      </Text>
                      <Switch
                        id="csv-mode"
                        isChecked={isCsvMode}
                        onChange={handleCsvToggle}
                      />
                    </Flex>
                  </Flex>

                  {isCsvMode ? (
                    renderCsvUploadForm()
                  ) : (
                    <VStack spacing={0}>
                      {/* by pass template */}
                      <FormControl px="24px" mt="12px">
                        <Checkbox
                          id="byPassTemplate"
                          {...register('byPassTemplate')}
                          onChange={() =>
                            handleCheckboxChange(onTogglePassTemplate, () => {
                              reset({ passTemplate: '' });
                              setSelectedPassTemplate('');
                            })
                          }
                          isDisabled={!filterEntityValue || isOpenGroupTag}
                        >
                          <Text
                            textStyle="bodyRegular"
                            color="secondaryDark"
                            fontSize="15px"
                          >
                            By pass template
                          </Text>
                        </Checkbox>
                        <Collapse
                          in={filterEntityValue && isOpenPassTemplate}
                          animateOpacity
                        >
                          <CustomReactSelect
                            options={passTemplatesList}
                            placeholder="Select pass template"
                            formatOptionLabel={
                              formatOptionLabelFilterPassTemplate
                            }
                            onChange={(passTemplate) =>
                              setSelectedPassTemplate({
                                label: passTemplate.label,
                                value: passTemplate.value,
                              })
                            }
                            value={selectedPassTemplate}
                            id="passTemplateFilter"
                            passTemplate
                          />
                        </Collapse>
                      </FormControl>
                      {/* by group tag */}
                      <FormControl px="24px" mt="12px">
                        <Checkbox
                          id="byGroupTag"
                          {...register('byGroupTag')}
                          onChange={() =>
                            handleCheckboxChange(onToggleGroupTag, () => {
                              reset({ groupTag: '' });
                              setSelectedGroupTag('');
                            })
                          }
                          isDisabled={!filterEntityValue || isOpenPassTemplate}
                        >
                          <Text
                            textStyle="bodyRegular"
                            color="secondaryDark"
                            fontSize="15px"
                          >
                            By group tag
                          </Text>
                        </Checkbox>
                        <Collapse
                          in={filterEntityValue && isOpenGroupTag}
                          animateOpacity
                        >
                          <CustomReactSelect
                            options={groupTagsList}
                            placeholder="Select group tag"
                            formatOptionLabel={formatOptionLabelFilterGroupTag}
                            onChange={(groupTag) =>
                              setSelectedGroupTag(groupTag)
                            }
                            value={selectedGroupTag}
                            id="groupTagFilter"
                          />
                        </Collapse>
                      </FormControl>
                    </VStack>
                  )}

                  <Text textStyle="bodyBold" color="secondaryDark" pt="20px">
                    {isCsvMode ? 'Note' : 'Message'}
                  </Text>

                  <Text textStyle="bodyRegular" color="secondaryDark">
                    {isCsvMode
                      ? "The CSV file should have a header row with 'pass_uuid' or 'ext_id and 'message' columns. Refer to documentation for more guidance"
                      : "Message header and icon are inherited from each pass's template."}
                  </Text>

                  {!isCsvMode && (
                    <FormControl isRequired={true}>
                      <FormLabel
                        pl="5px"
                        pt="12px"
                        pb="6px"
                        textStyle="bodyRegular"
                        color="secondaryDark"
                      >
                        Message text
                      </FormLabel>
                      <TextareaAutosize
                        variant="outlined"
                        value={messageText}
                        placeholder="Write message text here..."
                        id="messageText"
                        type="text"
                        resize="none"
                        rows={4}
                        onChange={(e) => {
                          messageCtx.updateMessageText(e.target.value);
                        }}
                        isDisabled={!filterEntityValue}
                      />
                    </FormControl>
                  )}

                  <Flex
                    direction="row"
                    w="full"
                    align="center"
                    justify="space-between"
                    pt="20px"
                  >
                    {!isCsvMode && (
                      <Text textStyle="bodyRegular" color="secondaryDark">
                        Message{' '}
                        <Text display="inline" textStyle="bodyBold">
                          {totalPasses}
                        </Text>{' '}
                        pass holder{totalPasses === 1 ? '' : 's'}
                      </Text>
                    )}
                    <Button
                      type="submit"
                      alt="Send message"
                      width={{ base: 'full', sm: 'auto' }}
                      isLoading={isSubmitting}
                      alignSelf="flex-end"
                      size="sm"
                      isDisabled={
                        isCsvMode
                          ? !selectedFile
                          : !filterEntityValue || totalPasses === 0
                      }
                    >
                      Send message
                    </Button>
                  </Flex>
                </VStack>
              </Box>
              <VStack align="flex-start" spacing={5}>
                <Box
                  borderRadius="15px"
                  background={modeBoxBg}
                  padding="20px 30px"
                  zIndex="1"
                  minW={{ base: 'none', md: 'fit-content' }}
                  position="sticky"
                  top="20px"
                  width="100%"
                  transition="top 0.3s"
                >
                  <Text textStyle="bodyBold" color="secondaryDark" pb="20px">
                    Preview push message
                  </Text>
                  <Grid
                    templateColumns={{ base: '1fr', '2xl': '1fr 1fr' }}
                    gap={4}
                  >
                    <GridItem>
                      <PreviewPassNotificationsApple
                        previewData={previewData}
                      />
                    </GridItem>
                    <GridItem>
                      <PreviewPassNotificationsAndroid
                        previewData={previewData}
                      />
                    </GridItem>
                  </Grid>
                </Box>
              </VStack>
            </Grid>
            <Stack
              spacing="10px"
              direction={{ base: 'column', sm: 'row' }}
              width={{ base: 'full', sm: 'auto' }}
            ></Stack>
          </form>

          {/* CSV Upload Confirmation Dialog */}
          <AlertDialog
            isOpen={isCsvModalOpen}
            leastDestructiveRef={cancelRef}
            onClose={onCsvModalClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Confirm CSV Upload
                </AlertDialogHeader>
                <AlertDialogBody>
                  Are you sure you want to upload this CSV file and send
                  messages?
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onCsvModalClose}>
                    Cancel
                  </Button>
                  <Button colorScheme="blue" onClick={handleCsvUpload} ml={3}>
                    Yes, Upload
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          {/* Regular Send Confirmation Dialog */}
          <MessagingConfirmationModal
            isOpen={isRegularModalOpen}
            onClose={onRegularModalClose}
            onConfirm={handleRegularSend}
            totalPasses={totalPasses}
          />
        </>
      ) : (
        <Center>
          <Spinner
            width="52px"
            height="52px"
            thickness="4px"
            speed="0.65s"
            emptyColor="quinaryBackground"
            mt={4}
            mx="auto"
          />
        </Center>
      )}
    </>
  );
};

export default MessagesCreatePage;
