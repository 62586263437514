import {
  Box,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Hide,
  Show,
  Stack,
  Switch,
  Text,
  Tooltip,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react';
import { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import PassTemplateContext from '../../../store/client/PassTemplateContext';
import FieldDetails from './FieldDetails';
import {
  CustomReactSelect,
  formatOptionLabelSelectPassType,
} from '../../common/CustomReactSelect';
import { formatOptionLabelSelectEntity } from '../../common/CustomReactSelect';
import ClearFieldsModal from '../../modals/ClearFieldsModal';
import { CustomExclamationMarkInverseIcon } from '../../../theme/icons/CustomExclamationMarkInverseIcon';

const FieldsContainer = (props) => {
  const {
    isEdit,
    editedFields,
    setEditedFields,
    showTooltipHandler,
    headerError,
  } = props;
  const passTemplateCtx = useContext(PassTemplateContext);
  const {
    passType,
    headerOneLabel,
    updateHeaderOneLabel,
    headerOneId,
    updateHeaderOneId,
    stampShortKey,
    updateStampShortKey,
    stampLongKey,
    updateStampLongKey,
    updateStampShortKeyField,
    updateStampLongKeyField,
    loyaltyConfigEnabled,
    updateLoyaltyConfigEnabled,
    loyaltyConfigNumberType,
    updateLoyaltyConfigNumberType,
  } = passTemplateCtx;
  const [headerOneOpen, setHeaderOneOpen] = useState(false);
  const [primaryOneOpen, setPrimaryOneOpen] = useState(false);
  const [fieldOneOpen, setFieldOneOpen] = useState(false);
  const [fieldTwoOpen, setFieldTwoOpen] = useState(false);
  const [fieldThreeOpen, setFieldThreeOpen] = useState(false);
  const [fieldFourOpen, setFieldFourOpen] = useState(false);
  const [fieldFiveOpen, setFieldFiveOpen] = useState(false);
  const [fieldSixOpen, setFieldSixOpen] = useState(false);
  const [backOneOpen, setBackOneOpen] = useState(false);
  const [backTwoOpen, setBackTwoOpen] = useState(false);
  const [backThreeOpen, setBackThreeOpen] = useState(false);
  const [backFourOpen, setBackFourOpen] = useState(false);
  const [backFiveOpen, setBackFiveOpen] = useState(false);
  const [isLargeForParallelDisplay] = useMediaQuery('(min-width: 1530px)');
  const [fieldsList, setFieldsList] = useState([]);
  const [modalFieldName, setModalFieldName] = useState('');
  const [editedFieldName, setEditedFieldName] = useState('');
  const [isClearFieldsOpen, setIsClearFieldsOpen] = useState(false);

  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormControlBg = useColorModeValue('white', '');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const modeTextBg = useColorModeValue('quaternaryBackground', 'primaryDark06');
  const modeFormBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('#ffffff', 'primaryDark');

  const checkPassType = useMemo(
    () =>
      ['storeCard', 'stampCard', 'coupon'].includes(passType) ? 'none' : '',
    [passType]
  );

  const balanceNumberTypeOptions = [
    {
      label: 'Integer',
      value: 'integer',
    },
    {
      label: 'Decimal',
      value: 'decimal',
    },
  ];

  const onClearFieldsOpen = useCallback((fieldName, editedFieldName) => {
    setModalFieldName(fieldName);
    setEditedFieldName(editedFieldName);
    setIsClearFieldsOpen(true);
  }, []);

  const onClearFieldsClose = useCallback(() => {
    setModalFieldName('');
    setIsClearFieldsOpen(false);
  }, []);

  const handleUpdateStampKeys = useCallback(
    (field, updatedValue) => {
      const {
        stampShortKey,
        stampLongKey,
        stampShortKeyField,
        stampLongKeyField,
      } = passTemplateCtx;
      if (!stampShortKey && !stampLongKey) {
        return;
      }
      if (stampShortKey && field === stampShortKeyField) {
        updateStampShortKey(updatedValue);
      } else if (stampLongKey && field === stampLongKeyField) {
        updateStampLongKey(updatedValue);
      }
    },
    [passTemplateCtx]
  );

  useEffect(() => {
    if (isEdit) {
      setEditedFields({
        ...editedFields,
        stamp_short_key: stampShortKey,
      });
    }
  }, [stampShortKey]);

  useEffect(() => {
    if (isEdit) {
      setEditedFields({
        ...editedFields,
        stamp_long_key: stampLongKey,
      });
    }
  }, [stampLongKey]);

  const renderFieldDetails = useCallback(
    (fields) => {
      return fields.map((field) => (
        <FieldDetails
          key={field.fieldName}
          {...field}
          isEdit={isEdit}
          editedFields={editedFields}
          setEditedFields={setEditedFields}
          showTooltipHandler={showTooltipHandler}
          onClearFieldsOpen={onClearFieldsOpen}
          isClearFieldsOpen={isClearFieldsOpen}
          handleUpdateStampKeys={handleUpdateStampKeys}
        />
      ));
    },
    [
      isEdit,
      editedFields,
      setEditedFields,
      showTooltipHandler,
      onClearFieldsOpen,
      isClearFieldsOpen,
      handleUpdateStampKeys,
    ]
  );

  const fieldDetailsConfig = useMemo(
    () => [
      {
        displayName:
          passType === 'stampCard'
            ? 'Stamp field'
            : loyaltyConfigEnabled
              ? 'Balance field'
              : 'Header field',
        fieldName: 'headerOne',
        editedFieldName: 'header_one',
        fieldOpen: headerOneOpen,
        setFieldOpen: setHeaderOneOpen,
        isRequired: passType !== 'generic',
        requiredFieldError: headerError,
        extraLabelText: passType === 'generic' && 'iOS version',
        balanceTooltip: loyaltyConfigEnabled,
        displayDefaultValue: passType !== 'stampCard',
        defaultValuePlaceholder: loyaltyConfigEnabled
          ? loyaltyConfigNumberType === 'integer'
            ? '0'
            : '0.00'
          : '',
      },
      {
        displayName: 'Central field',
        fieldName: 'primaryOne',
        editedFieldName: 'primary_one',
        fieldOpen: primaryOneOpen,
        setFieldOpen: setPrimaryOneOpen,
        display: passType === 'generic' ? '' : 'none',
        displayDefaultValue: true,
      },
      {
        displayName: 'Field 1',
        fieldName: 'secOne',
        editedFieldName: 'sec_one',
        fieldOpen: fieldOneOpen,
        setFieldOpen: setFieldOneOpen,
        displayDefaultValue: true,
      },
      {
        displayName: 'Field 2',
        fieldName: 'secTwo',
        editedFieldName: 'sec_two',
        fieldOpen: fieldTwoOpen,
        setFieldOpen: setFieldTwoOpen,
        displayDefaultValue: true,
      },
      {
        displayName: 'Field 3',
        fieldName: 'secThree',
        editedFieldName: 'sec_three',
        fieldOpen: fieldThreeOpen,
        setFieldOpen: setFieldThreeOpen,
        displayDefaultValue: true,
      },
      {
        displayName: 'Field 4',
        fieldName: 'auxOne',
        editedFieldName: 'aux_one',
        fieldOpen: fieldFourOpen,
        setFieldOpen: setFieldFourOpen,
        displayDefaultValue: true,
      },
      {
        displayName: 'Field 5',
        fieldName: 'auxTwo',
        editedFieldName: 'aux_two',
        fieldOpen: fieldFiveOpen,
        setFieldOpen: setFieldFiveOpen,
        display: checkPassType,
        displayDefaultValue: true,
      },
      {
        displayName: 'Field 6',
        fieldName: 'auxThree',
        editedFieldName: 'aux_three',
        fieldOpen: fieldSixOpen,
        setFieldOpen: setFieldSixOpen,
        display: checkPassType,
        displayDefaultValue: true,
      },
      {
        displayName: 'Back field 1',
        fieldName: 'backOne',
        editedFieldName: 'back_one',
        fieldOpen: backOneOpen,
        setFieldOpen: setBackOneOpen,
        displayDefaultValue: true,
      },
      {
        displayName: 'Back field 2',
        fieldName: 'backTwo',
        editedFieldName: 'back_two',
        fieldOpen: backTwoOpen,
        setFieldOpen: setBackTwoOpen,
        displayDefaultValue: true,
      },
      {
        displayName: 'Back field 3',
        fieldName: 'backThree',
        editedFieldName: 'back_three',
        fieldOpen: backThreeOpen,
        setFieldOpen: setBackThreeOpen,
        displayDefaultValue: true,
      },
      {
        displayName: 'Back field 4',
        fieldName: 'backFour',
        editedFieldName: 'back_four',
        fieldOpen: backFourOpen,
        setFieldOpen: setBackFourOpen,
        displayDefaultValue: true,
      },
      {
        displayName: 'Back field 5',
        fieldName: 'backFive',
        editedFieldName: 'back_five',
        fieldOpen: backFiveOpen,
        setFieldOpen: setBackFiveOpen,
        displayDefaultValue: true,
      },
    ],
    [
      passTemplateCtx,
      headerOneOpen,
      primaryOneOpen,
      fieldOneOpen,
      fieldTwoOpen,
      fieldThreeOpen,
      fieldFourOpen,
      fieldFiveOpen,
      fieldSixOpen,
      backOneOpen,
      backTwoOpen,
      backThreeOpen,
      backFourOpen,
      backFiveOpen,
      checkPassType,
      headerError,
    ]
  );

  useEffect(() => {
    if (passType !== 'generic' && headerError) {
      setHeaderOneOpen(true);
    }
  }, [headerError, passType]);

  useEffect(() => {
    const fields = [
      'secOne',
      'secTwo',
      'secThree',
      'auxOne',
      'auxTwo',
      'auxThree',
      'backOne',
      'backTwo',
      'backThree',
      'backFour',
      'backFive',
    ].map((key) => ({ label: passTemplateCtx[`${key}Id`], value: key }));
    setFieldsList(fields.filter((field) => !!field.label));
    fields.forEach((field) => {
      if (field.label === stampShortKey) {
        updateStampShortKeyField(field.value);
      }
      if (field.label === stampLongKey) {
        updateStampLongKeyField(field.value);
      }
    });
  }, [passTemplateCtx]);

  useEffect(() => {
    if (isEdit) return;
    const defaultLabels = {
      storeCard: loyaltyConfigEnabled ? 'Balance label' : 'Header label',
      stampCard: 'Stamp label',
      generic: loyaltyConfigEnabled ? 'Balance label' : 'Header label',
      eventTicket: loyaltyConfigEnabled ? 'Balance label' : 'Header label',
    };
    if (
      ['Header label', 'Balance label', 'Stamp label'].includes(headerOneLabel)
    ) {
      updateHeaderOneLabel(defaultLabels[passType]);
    }
  }, [passTemplateCtx, isEdit, loyaltyConfigEnabled]);

  useEffect(() => {
    if (isEdit) return;
    const headerIdMap = {
      storeCard: loyaltyConfigEnabled ? 'balanceLabel' : 'headerLabel',
      stampCard: 'stampLabel',
      generic: loyaltyConfigEnabled ? 'balanceLabel' : 'headerLabel',
      eventTicket: loyaltyConfigEnabled ? 'balanceLabel' : 'headerLabel',
    };

    if (['headerLabel', 'balanceLabel', 'stampLabel'].includes(headerOneId)) {
      updateHeaderOneId(headerIdMap[passType]);
    }
  }, [passType, isEdit, loyaltyConfigEnabled]);

  useEffect(() => {
    if (!isEdit) return;
    const newHeaderOneId = loyaltyConfigEnabled
      ? 'balanceLabel'
      : 'headerLabel';
    const newHeaderOneLabel = loyaltyConfigEnabled
      ? 'Balance label'
      : 'Header label';
    if (headerOneId === 'headerLabel' || headerOneId === 'balanceLabel') {
      updateHeaderOneId(newHeaderOneId);
      editedFields.header_one_id = newHeaderOneId;
    }
    if (
      headerOneLabel === 'Header label' ||
      headerOneLabel === 'Balance label'
    ) {
      updateHeaderOneLabel(newHeaderOneLabel);
      editedFields.header_one_label = newHeaderOneLabel;
    }
  }, [loyaltyConfigEnabled]);

  return (
    <Box
      className="autofillForDarkBg"
      borderRadius="15px"
      w="full"
      mt="20px"
      overflow="hidden"
    >
      <Box
        className="primaryBoxShadow"
        bg={modeFormBoxBg}
        w="full"
        borderRadius="15px"
      >
        <Stack
          p="14px 14px 14px 24px"
          borderRadius="15px 15px 0 0"
          bg={modeTextBg}
          direction={{ base: 'column', '2xl': 'row' }}
        >
          <Text fontSize="18px" textStyle="headingFamilyMedium">
            Fields
          </Text>
        </Stack>
        <Divider borderColor={modeBorderColor} />
        {passType !== 'stampCard' && (
          <>
            <Box id="loyaltyConfigEnabled">
              <FormControl bg={modeFormControlBg}>
                <Flex alignItems="center">
                  <Hide below="2xl">
                    <Box pl="24px" flexShrink={0} w="240px">
                      <Flex
                        direction="row"
                        align="center"
                        alignContent="center"
                        alignItems="center"
                        gap={1}
                      >
                        <FormLabel m={0}>Enable loyalty balance</FormLabel>
                        <Tooltip
                          label={
                            'Allows a balance field on the pass that can be updated via our loyalty API.'
                          }
                          hasArrow
                          placement="top"
                          fontSize="12px"
                          bg={bgTooltip}
                          color={textTooltip}
                          w="205px"
                          borderRadius="6px"
                          textAlign="center"
                          p="10px"
                        >
                          <Box cursor="pointer" pb={0.5}>
                            <CustomExclamationMarkInverseIcon
                              boxSize="12px"
                              mode={'light'}
                            />
                          </Box>
                        </Tooltip>
                      </Flex>
                    </Box>
                  </Hide>
                  <Box className="inner-box" p="14px" w="full" bg={modeLabelBg}>
                    <Show below="2xl">
                      <Flex
                        direction="row"
                        align="center"
                        alignContent="center"
                        alignItems="center"
                        gap={1}
                      >
                        <FormLabel m={0}>Enable loyalty balance</FormLabel>
                        <Tooltip
                          label={
                            'Allows a balance field on the pass that can be updated via our loyalty API.'
                          }
                          hasArrow
                          placement="top"
                          fontSize="12px"
                          bg={bgTooltip}
                          color={textTooltip}
                          w="205px"
                          borderRadius="6px"
                          textAlign="center"
                          p="10px"
                        >
                          <Box cursor="pointer" pb={0.5}>
                            <CustomExclamationMarkInverseIcon
                              boxSize="12px"
                              mode={'light'}
                            />
                          </Box>
                        </Tooltip>
                      </Flex>
                    </Show>
                    <Flex
                      direction="row"
                      gap={3}
                      alignItems="center"
                      ml={3}
                      py={1}
                    >
                      <Switch
                        id="loyaltyBalance"
                        isChecked={loyaltyConfigEnabled}
                        onChange={() => {
                          updateLoyaltyConfigEnabled(!loyaltyConfigEnabled);
                          if (
                            props.isEdit &&
                            passTemplateCtx.passType !== 'stampCard'
                          ) {
                            props.setEditedFields({
                              ...props.editedFields,
                              loyalty_config_attributes: {
                                enabled: !loyaltyConfigEnabled,
                                number_type: loyaltyConfigNumberType,
                              },
                            });
                          }
                        }}
                      />
                    </Flex>
                  </Box>
                </Flex>
              </FormControl>
              <Divider borderColor={modeBorderColor} />
            </Box>
          </>
        )}
        <Collapse
          in={loyaltyConfigEnabled && passType !== 'stampCard'}
          id="loyaltyConfigNumberType"
        >
          <FormControl isRequired={true} bg={modeFormControlBg}>
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel>Balance number type</FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <Box>
                    <FormLabel>Balance number type</FormLabel>
                  </Box>
                </Show>
                <CustomReactSelect
                  options={balanceNumberTypeOptions}
                  formatOptionLabel={formatOptionLabelSelectPassType}
                  value={loyaltyConfigNumberType}
                  variant="filledForDarkBg"
                  id="passType"
                  type="text"
                  placeholder={
                    balanceNumberTypeOptions.find(
                      (type) => type.value === loyaltyConfigNumberType
                    )?.label
                  }
                  onChange={(e) => {
                    updateLoyaltyConfigNumberType(e.value);
                    if (
                      props.isEdit &&
                      passTemplateCtx.passType !== 'stampCard'
                    ) {
                      props.setEditedFields({
                        ...props.editedFields,
                        loyalty_config_attributes: {
                          enabled: loyaltyConfigEnabled,
                          number_type: e.value,
                        },
                      });
                    }
                  }}
                  autoComplete="off"
                  passType
                />
              </Box>
            </Flex>
          </FormControl>
          <Divider borderColor={modeBorderColor} />
        </Collapse>
        {renderFieldDetails(fieldDetailsConfig)}
        {passType === 'stampCard' && (
          <>
            <FormControl bg={modeFormControlBg} borderRadius="0 15px 0 0">
              <Flex alignItems="center">
                <Hide below="2xl">
                  <Box pl="24px" flexShrink={0} w="240px">
                    <FormLabel m={0}>Rewards info field 1</FormLabel>
                  </Box>
                </Hide>
                <Box p="14px" w="full" bg={modeLabelBg}>
                  <Show below="2xl">
                    <FormLabel>Rewards info field 1</FormLabel>
                  </Show>
                  <CustomReactSelect
                    options={fieldsList}
                    menuPortalTarget={document.body}
                    placeholder={
                      isLargeForParallelDisplay
                        ? 'Select a label'
                        : 'Select a label from the list'
                    }
                    noOptionsMessage={() => 'No labels found'}
                    menuPlacement="auto"
                    formatOptionLabel={formatOptionLabelSelectEntity}
                    isOptionDisabled={(option) =>
                      stampLongKey
                        ? option.label.toLowerCase() ===
                          stampLongKey.toLowerCase()
                        : false
                    }
                    onChange={(field) => {
                      updateStampShortKeyField(!field ? '' : field.value);
                      updateStampShortKey(!field ? '' : field.label);
                      if (isEdit) {
                        setEditedFields({
                          ...editedFields,
                          stamp_short_key: !field ? '' : field.label,
                        });
                      }
                    }}
                    value={
                      !!stampShortKey && {
                        label: stampShortKey,
                        value: 'shortKey',
                      }
                    }
                    id="passTemplate"
                    templateEntity
                    rewardsInfoField
                  />
                  <FormErrorMessage></FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>
            <Divider borderColor={modeBorderColor} />
            <FormControl bg={modeFormControlBg} borderRadius="0 0 15px 15px">
              <Flex alignItems="center">
                <Hide below="2xl">
                  <Box pl="24px" flexShrink={0} w="240px">
                    <FormLabel m={0}>Rewards info field 2</FormLabel>
                  </Box>
                </Hide>
                <Box
                  p="14px"
                  w="full"
                  bg={modeLabelBg}
                  borderRadius={{ base: '0 0 15px 15px', '2xl': '0 0 15px 0' }}
                >
                  <Show below="2xl">
                    <FormLabel>Rewards info field 2</FormLabel>
                  </Show>
                  <CustomReactSelect
                    options={fieldsList}
                    menuPortalTarget={document.body}
                    placeholder={
                      isLargeForParallelDisplay
                        ? 'Select a label'
                        : 'Select a label from the list'
                    }
                    noOptionsMessage={() => 'No labels found'}
                    menuPlacement="auto"
                    formatOptionLabel={formatOptionLabelSelectEntity}
                    isOptionDisabled={(option) =>
                      stampShortKey
                        ? option.label.toLowerCase() ===
                          stampShortKey.toLowerCase()
                        : false
                    }
                    onChange={(field) => {
                      updateStampLongKeyField(!field ? '' : field.value);
                      updateStampLongKey(!field ? '' : field.label);
                      if (isEdit) {
                        setEditedFields({
                          ...editedFields,
                          stamp_long_key: !field ? '' : field.label,
                        });
                      }
                    }}
                    value={
                      !!stampLongKey && {
                        label: stampLongKey,
                        value: 'longKey',
                      }
                    }
                    id="passTemplate"
                    templateEntity
                    rewardsInfoField
                  />
                  <FormErrorMessage></FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>
          </>
        )}
      </Box>
      <ClearFieldsModal
        isEdit={isEdit}
        editedFields={editedFields}
        setEditedFields={setEditedFields}
        isOpen={isClearFieldsOpen}
        onClose={onClearFieldsClose}
        fieldName={modalFieldName}
        editedFieldName={editedFieldName}
        handleUpdateStampKeys={handleUpdateStampKeys}
      />
    </Box>
  );
};

export default FieldsContainer;
