import appsignal from '../../../appsignal';
import { useContext, useMemo, useState } from 'react';
import LiveEventDataListTable from './LiveEventDataListTable';
import { useEffect } from 'react';
import LiveEventDataListContext from '../../../store/client/LiveEventDataListContext';
import { useMediaQuery, useToast } from '@chakra-ui/react';
import CustomToast from '../../../common/CustomToast';
import { getErrorResponsePayload } from '../../../utils/ajax';
import { useIsMount } from '../../../hooks/useIsMount';
import { HttpContext } from '../../../context/HttpContext';
import { MeContext } from '../../../context/MeContext';
import moment from 'moment';
import ActionCable from 'actioncable';
import { format, utcToZonedTime } from 'date-fns-tz';
import runtimeEnv from '@mars/heroku-js-runtime-env';

const LiveEventDataList = () => {
  const { authAxios } = useContext(HttpContext);
  const {
    initialLiveEventData,
    initialLiveEventData: { items, included, loading, offset },
    updateInitialLiveEventData,
    filterValues,
  } = useContext(LiveEventDataListContext);
  const toast = useToast();
  const [isLargerThanSM] = useMediaQuery('(min-width: 30em)');
  const [isSmallerThan350px] = useMediaQuery('(max-width: 350px)');
  const [scanHeartbeat, setScanHeartbeat] = useState(null);
  const [scanInfo, setScanInfo] = useState(null);
  const isMount = useIsMount();
  const [query, setQuery] = useState('');
  const [formattedData, setFormattedData] = useState([]);
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const [bearerToken, setBearerToken] = useState(null);
  const [includedData, setIncludedData] = useState([]);
  const meCtx = useContext(MeContext);
  const { filterEntity } = meCtx.state;
  const env = runtimeEnv();

  // websocket

  useEffect(() => {
    if (!bearerToken) {
      return;
    }
    // Remove HTTPS from the URL
    const strippedUrl = (
      env.REACT_APP_API_URL_AXIOS ??
      window.env?.REACT_APP_API_URL_AXIOS ??
      ''
    ).replace(/^https:\/\//, '');
    // Add the /cable path to the URL
    const cableUrl = `wss://${strippedUrl}/cable`;
    // const cableUrl = `ws://localhost:3000/cable`;
    // Open connection to the WebSocket server
    const consumer = ActionCable.createConsumer(cableUrl);

    consumer.subscriptions.create('AuthenticationChannel', {
      connected() {
        console.log('connected');
        // Send an authentication message right after connecting
        this.perform('authenticate', {
          token: bearerToken,
        });
      },

      disconnected() {
        console.log('disconnected');
      },

      received(data) {
        console.log('received', data);
        if (data.status === 'rejected') {
          console.log('Something went wrong');
          // Handle unauthorized connection, e.g., close the WebSocket connection
        } else if (data.status === 'authenticated') {
          console.log('authenticated');
          // Upon successful authentication, subscribe to PassTapsChannel
          subscribeToPassTapsChannel();
        }
      },
    });

    function subscribeToPassTapsChannel() {
      consumer.subscriptions.create(
        {
          channel: 'PassTapsChannel',
        },
        {
          connected: () => console.log('socket connected!'),
          disconnected: () => console.log('socket disconnected!'),
          received: (message) => {
            setIncludedData((prev) => [...prev, ...(message?.included || [])]);
            setScanInfo(message.data);
            setScanHeartbeat((prev) => !prev);
          },
        }
      );
    }
  }, [bearerToken]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await authAxios.get(`keys`);
        const keys = data?.data || [];
        setBearerToken(keys[0]?.token);
      } catch (error) {
        appsignal.sendError(error);
        console.log(error);
        const { message, code } = getErrorResponsePayload(error);
        code !== 401 &&
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={message}
                // description="Please try again later."
                onClose={props.onClose}
              />
            ),
          });
      }
    };
    getData();
  }, []);

  // render data in table only if it matches the filter - all events is default
  useEffect(() => {
    const queryReader =
      filterValues.reader !== '' ? `&reader=${filterValues.reader.value}` : '';
    const queryEvent =
      filterValues.event !== '' ? `&event=${filterValues.event.id}` : '';
    const queryGroupTag =
      filterValues.groupTag !== ''
        ? `&groupTag=${filterValues.groupTag.label}`
        : '';
    const query = `${queryReader}${queryEvent}${queryGroupTag}`;
    setQuery(query);
    updateInitialLiveEventData((prev) => {
      return {
        ...prev,
        items: [],
        included: [],
        offset: 0,
        loading: true,
      };
    });
  }, [filterValues]);

  const fetchData = async (currentOffset = offset) => {
    try {
      let response;
      if (filterEntity) {
        response = await authAxios.get(
          `api/v1/entities/${filterEntity.uuid}/taps?limit=30&offset=${currentOffset}${query}`
        );
      } else {
        response = await authAxios.get(
          `taps?limit=30&offset=${currentOffset}${query}`
        );
      }
      const data = response?.data?.data || [];
      const total = response?.data?.meta?.totalPassTaps ?? 0;
      const hasMore = total >= items.length;
      setIncludedData((prev) => [
        ...prev,
        ...(response?.data?.included ? response.data.included : []),
      ]);
      updateInitialLiveEventData((prev) => {
        return {
          ...prev,
          items: currentOffset === 0 ? data : [...prev.items, ...data],
          included: [...prev.included, ...(response?.data?.included || [])],
          offset: currentOffset + 30,
          total,
          hasMore,
          loading: false,
          status: 'resolved',
        };
      });
    } catch (error) {
      appsignal.sendError(error);
      updateInitialLiveEventData({
        ...initialLiveEventData,
        items: [],
        total: 0,
        loading: false,
        error: 'Something went wrong',
        status: 'rejected',
      });
      const { message, code } = getErrorResponsePayload(error);
      code !== 401 &&
        toast({
          render: (props) => (
            <CustomToast
              status="error"
              title={message ? message : `Something went wrong`}
              description={!message && 'Please try again later'}
              onClose={props.onClose}
            />
          ),
        });
    }
  };

  useEffect(() => {
    fetchData(0);
  }, [query, filterEntity]);

  // update data in our table with new scan data in case scan data happens
  useEffect(() => {
    updateInitialLiveEventData({
      ...initialLiveEventData,
      items: [scanInfo, ...items],
      loading: isMount ? true : false,
      offset: isMount ? 0 : offset + 1,
    });
    return;
  }, [scanHeartbeat]);

  const columns = useMemo(
    () => [
      {
        Header: 'External ID',
        accessor: 'externalId',
        className: 'modeTdColor',
        style: {
          fontFamily: 'Inter Bold, sans-serif',
          fontSize: '16px',
          wordBreak: 'break-word',
          minWidth: '30px',
          width: '20%',
        },
      },
      {
        Header: 'Full Name',
        accessor: 'fullName',
        className: 'modeTdColor',
        style: {
          fontFamily: 'Inter Bold, sans-serif',
          fontSize: '16px',
          wordBreak: 'break-word',
          minWidth: '30px',
          width: '20%',
        },
      },
      {
        Header: 'View Pass',
        accessor: 'viewPass',
        style: {
          minWidth: '30px',
          width: '170px',
          wordBreak: 'break-word',
        },
      },
      {
        Header: isLargerThanSM ? 'Event name' : 'Event',
        accessor: 'eventName',
        style: {
          minWidth: '30px',
          width: '170px',
          wordBreak: 'break-word',
        },
      },
      {
        Header: isLargerThanSM ? 'Reader name' : 'Reader',
        accessor: 'readerName',
        style: {
          minWidth: '50px',
          width: '160px',
          wordBreak: 'break-word',
        },
      },
      // {
      //   Header: 'Reader ID',
      //   accessor: 'readerId',
      //   style: {
      //     minWidth: '50px',
      //     width: '150px',
      //     wordBreak: 'break-word',
      //   },
      // },
      {
        Header: isSmallerThan350px ? '' : 'Status',
        accessor: 'success',
        style: {
          width: isSmallerThan350px ? '0px' : '110px',
        },
      },
      {
        Header: 'Time',
        accessor: 'time',
        style: {
          width: '120px',
        },
      },
      {
        Header: '',
        accessor: 'moreInfo',
        style: {
          width: '10px',
          padding: 0,
        },
      },
    ],
    []
  );

  const getPassExternalId = async (uuid, included) => {
    try {
      const pass = includedData.find((item) => item.id === uuid);
      return pass?.attributes?.extId || 'N/A';
    } catch (error) {
      appsignal.sendError(error);
      console.log(error);
    }
  };

  const getFullNames = async (uuid, included) => {
    try {
      const pass = includedData.find((item) => item.id === uuid);
      return pass?.attributes?.passContent?.fullName || 'N/A';
    } catch (error) {
      appsignal.sendError(error);
      console.log(error);
    }
  };

  const getReaderName = (uuid, included) => {
    try {
      const reader = includedData.find((item) => item.id === uuid);
      return reader?.attributes?.name || 'N/A';
    } catch (error) {
      appsignal.sendError(error);
      console.log(error);
    }
  };

  const fetchPassExternalIds = async (data, included) => {
    const passUuids = data.map((item) => item?.relationships.pass.data.id);
    const passExternalIds = await Promise.all(
      passUuids.map((uuid) => getPassExternalId(uuid, included))
    );
    return passExternalIds;
  };

  const fetchFullNames = async (data, included) => {
    const passUuids = data.map((item) => item?.relationships.pass.data.id);
    const fullNames = await Promise.all(
      passUuids.map((uuid) => getFullNames(uuid, included))
    );
    return fullNames;
  };

  const fetchReaderNames = (data, included) => {
    const readerIds = data.map((item) => item?.relationships.reader.data.id);
    const readerNames = readerIds.map((uuid) => getReaderName(uuid, included));
    return readerNames;
  };

  const fetchEventNames = async (data) => {
    const eventNames = data.map((item) => item?.attributes.event_name);
    return eventNames;
  };

  const getDisplayTime = (isoTimeString) => {
    const zonedDate = utcToZonedTime(isoTimeString, timeZone);
    const formattedDate = format(zonedDate, 'dd/MM/yyyy hh:mm:ss a', timeZone);
    return formattedDate;
  };

  const normalizedData = (
    data,
    passExternalIds,
    eventNames,
    readerNames,
    fullNames
  ) => {
    if (data?.length > 0) {
      return data
        .map((item, index) => {
          const passExternalId = passExternalIds[index];
          const fullName = fullNames[index];
          const readerName = readerNames[index];
          const newItem = {
            ...item,
            externalId: passExternalId,
            fullName: fullName,
            eventName: item?.attributes.eventName || 'N/A',
            readerName: readerName,
            readerId: item?.relationships.reader.data.id,
            success: item?.attributes.successful,
            time: getDisplayTime(item?.attributes.tappedAt),
            reasonFailed: item?.attributes.refusalReason,
            viewPass: '/passes/' + item?.relationships.pass.data.id,
          };
          return newItem;
        })
        .filter((item) => item !== null);
    }
    return [];
  };

  useEffect(() => {
    const fetchAndProcessData = async () => {
      try {
        const filteredItems = items.filter((item) => item !== null);
        const passExternalIds = await fetchPassExternalIds(
          filteredItems,
          included
        );
        const fullNames = await fetchFullNames(filteredItems, included);
        const readerNames = fetchReaderNames(filteredItems, included);
        const eventNames = await fetchEventNames(filteredItems);
        const filteredData = normalizedData(
          filteredItems,
          passExternalIds,
          eventNames,
          readerNames,
          fullNames
        );
        setFormattedData(filteredData);
      } catch (error) {
        appsignal.sendError(error);
        console.error(error);
      }
    };
    fetchAndProcessData();
  }, [items]);

  const filtersApplied =
    filterValues.reader !== '' ||
    filterValues.event !== '' ||
    filterValues.groupTag !== '';

  return (
    <LiveEventDataListTable
      columns={columns}
      update={fetchData}
      data={formattedData}
      loading={loading}
      query={query}
      filtersApplied={filtersApplied}
      scanHeartbeat={scanHeartbeat}
    />
  );
};

export default LiveEventDataList;
