import appsignal from '../../../appsignal';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import {
  useToast,
  Spinner,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { CustomDuplicateIcon } from '../../../theme/icons/CustomDuplicateIcon';
import { HttpContext } from '../../../context/HttpContext';
import CustomToast from '../../../common/CustomToast';
import { getErrorResponsePayload } from '../../../utils/ajax';

import { getBase64DataForSubmit } from '../../../utils/base64';

import placeholderLogo from '../../../assets/images/logo.png';
import placeholderIcon from '../../../assets/images/icon.png';
import placeholderBanner from '../../../assets/images/banner.png';
import placeholderThumbnail from '../../../assets/images/thumbnail.png';
import placeholderMonochromeBadge from '../../../assets/images/monochrome-badge.png';

const DuplicateButton = (props) => {
  const { templateData } = props;
  const authAxios = useContext(HttpContext).authAxios;
  const toast = useToast();
  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('white', 'primaryDark');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const prepareDataForSubmit = () => {
    let submittedData = {
      name: templateData.name,
      template_type: templateData.passType,
      event_id: templateData.eventId,
      header_one_id: templateData.header.header_one.id,
      header_one_label: templateData.header.header_one.label,
      header_one_default_value: templateData.header.header_one.default_value,
      primary_one_id: templateData.primary.primary_one.id,
      primary_one_label: templateData.primary.primary_one.label,
      primary_one_default_value: templateData.primary.primary_one.default_value,
      description: templateData.description,
      logo_image: templateData.logoImage,
      icon_image: templateData.iconLogo,
      banner_image: templateData.bannerImage,
      google_banner_image: templateData.googleBannerImage,
      monochrome_badge: templateData.monoChromeBadge,
      thumbnail_image: templateData.thumbnailImage,
      sec_one_id: templateData.secOneId,
      sec_one_label: templateData.secOneLabel,
      sec_one_default_value: templateData.secOneValue,
      sec_two_id: templateData.secTwoId,
      sec_two_label: templateData.secTwoLabel,
      sec_two_default_value: templateData.secTwoValue,
      sec_three_id: templateData.secThreeId,
      sec_three_label: templateData.secThreeLabel,
      sec_three_default_value: templateData.secThreeValue,
      aux_one_id: templateData.auxOneId,
      aux_one_label: templateData.auxOneLabel,
      aux_one_default_value: templateData.auxOneValue,
      aux_two_id: templateData.auxTwoId,
      aux_two_label: templateData.auxTwoLabel,
      aux_two_default_value: templateData.auxTwoValue,
      aux_three_id: templateData.auxThreeId,
      aux_three_label: templateData.auxThreeLabel,
      aux_three_default_value: templateData.auxThreeValue,
      back_one_id: templateData.back_fields.back_one.id,
      back_one_label: templateData.back_fields.back_one.label,
      back_one_default_value: templateData.back_fields.back_one.default_value,
      back_two_id: templateData.back_fields.back_two.id,
      back_two_label: templateData.back_fields.back_two.label,
      back_two_default_value: templateData.back_fields.back_two.default_value,
      back_three_id: templateData.back_fields.back_three.id,
      back_three_label: templateData.back_fields.back_three.label,
      back_three_default_value:
        templateData.back_fields.back_three.default_value,
      back_four_id: templateData.back_fields.back_four.id,
      back_four_label: templateData.back_fields.back_four.label,
      back_four_default_value: templateData.back_fields.back_four.default_value,
      back_five_id: templateData.back_fields.back_five.id,
      back_five_label: templateData.back_fields.back_five.label,
      back_five_default_value: templateData.back_fields.back_five.default_value,
      text_color: templateData.bodyFontColor,
      background_color: templateData.passBackgroundColor,
      label_color: templateData.titleFontColor,
      notification_header: templateData.notification_header,
      central_title: templateData.central_title,
      google_nfc_enabled: templateData.google_nfc_enabled,
      apple_app_link: templateData.apple_app_link,
      google_app_link_title: templateData.google_app_link.title,
      google_app_link_icon_url: templateData.google_app_link.icon_url,
      google_app_link_description: templateData.google_app_link.description,
      google_app_link_play_store_url:
        templateData.google_app_link.play_store_url,
      google_app_link_call_to_action_text:
        templateData.google_app_link.call_to_action_text,
      locations: templateData.locations,
      beacons: templateData.beacons,
      watermark_color: templateData.watermark.color || 'light',
      watermark_position: templateData.watermark.position || 'bottomRight',
      google_banner_watermark_color:
        templateData.google_banner_watermark.color || 'light',
      google_banner_watermark_position:
        templateData.google_banner_watermark.position || 'bottomRight',
    };

    if (templateData.passType === 'stampCard') {
      submittedData = {
        ...submittedData,
        stamp_count: Number(templateData.stampCount),
        stamp_short_key: templateData.stampShortKey,
        stamp_long_key: templateData.stampLongKey,
        stamp_zero_message: templateData.stampZeroMessage,
        stamp_one_message: templateData.stampOneMessage,
        stamp_two_message: templateData.stampTwoMessage,
        stamp_three_message: templateData.stampThreeMessage,
        stamp_four_message: templateData.stampFourMessage,
        stamp_five_message: templateData.stampFiveMessage,
        stamp_six_message: templateData.stampSixMessage,
        stamp_seven_message: templateData.stampSevenMessage,
        stamp_eight_message: templateData.stampEightMessage,
        stamp_nine_message: templateData.stampNineMessage,
        stamp_ten_message: templateData.stampTenMessage,
        stamp_eleven_message: templateData.stampElevenMessage,
        stamp_twelve_message: templateData.stampTwelveMessage,
        stamp_zero_short_value: templateData.stampZeroShortValue,
        stamp_one_short_value: templateData.stampOneShortValue,
        stamp_two_short_value: templateData.stampTwoShortValue,
        stamp_three_short_value: templateData.stampThreeShortValue,
        stamp_four_short_value: templateData.stampFourShortValue,
        stamp_five_short_value: templateData.stampFiveShortValue,
        stamp_six_short_value: templateData.stampSixShortValue,
        stamp_seven_short_value: templateData.stampSevenShortValue,
        stamp_eight_short_value: templateData.stampEightShortValue,
        stamp_nine_short_value: templateData.stampNineShortValue,
        stamp_ten_short_value: templateData.stampTenShortValue,
        stamp_eleven_short_value: templateData.stampElevenShortValue,
        stamp_twelve_short_value: templateData.stampTwelveShortValue,
        stamp_zero_long_value: templateData.stampZeroLongValue,
        stamp_one_long_value: templateData.stampOneLongValue,
        stamp_two_long_value: templateData.stampTwoLongValue,
        stamp_three_long_value: templateData.stampThreeLongValue,
        stamp_four_long_value: templateData.stampFourLongValue,
        stamp_five_long_value: templateData.stampFiveLongValue,
        stamp_six_long_value: templateData.stampSixLongValue,
        stamp_seven_long_value: templateData.stampSevenLongValue,
        stamp_eight_long_value: templateData.stampEightLongValue,
        stamp_nine_long_value: templateData.stampNineLongValue,
        stamp_ten_long_value: templateData.stampTenLongValue,
        stamp_eleven_long_value: templateData.stampElevenLongValue,
        stamp_twelve_long_value: templateData.stampTwelveLongValue,
        stamp_zero_image: templateData.stampZeroImage,
        stamp_one_image: templateData.stampOneImage,
        stamp_two_image: templateData.stampTwoImage,
        stamp_three_image: templateData.stampThreeImage,
        stamp_four_image: templateData.stampFourImage,
        stamp_five_image: templateData.stampFiveImage,
        stamp_six_image: templateData.stampSixImage,
        stamp_seven_image: templateData.stampSevenImage,
        stamp_eight_image: templateData.stampEightImage,
        stamp_nine_image: templateData.stampNineImage,
        stamp_ten_image: templateData.stampTenImage,
        stamp_eleven_image: templateData.stampElevenImage,
        stamp_twelve_image: templateData.stampTwelveImage,
      };
    }

    if (templateData.logoImage !== placeholderLogo) {
      let logoImageBase64 = getBase64DataForSubmit(templateData.logoImage);
      if (logoImageBase64 !== undefined) {
        submittedData = {
          ...submittedData,
          logoImageBase64,
        };
      }
    }

    if (templateData.iconLogo !== placeholderIcon) {
      let iconLogoBase64 = getBase64DataForSubmit(templateData.iconLogo);
      if (iconLogoBase64 !== undefined) {
        submittedData = {
          ...submittedData,
          iconLogoBase64,
        };
      }
    }

    if (templateData.bannerImage !== placeholderBanner) {
      let bannerImageBase64 = getBase64DataForSubmit(templateData.bannerImage);
      if (bannerImageBase64 !== undefined) {
        submittedData = {
          ...submittedData,
          bannerImageBase64,
        };
      }
    }

    if (templateData.thumbnailImage !== placeholderThumbnail) {
      let thumbnailImageBase64 = getBase64DataForSubmit(
        templateData.thumbnailImage
      );
      if (thumbnailImageBase64 !== undefined) {
        submittedData = {
          ...submittedData,
          thumbnailImageBase64,
        };
      }
    }

    if (templateData.monoChromeBadge !== placeholderMonochromeBadge) {
      let monoChromeBadgeBase64 = getBase64DataForSubmit(
        templateData.monoChromeBadge
      );
      if (monoChromeBadgeBase64 !== undefined) {
        submittedData = {
          ...submittedData,
          monoChromeBadgeBase64,
        };
      }
    }

    if (templateData.passType === 'stampCard') {
      if (templateData.stampZeroImage !== placeholderBanner) {
        let stampZeroImageBase64 = getBase64DataForSubmit(
          templateData.stampZeroImage
        );
        if (stampZeroImageBase64 !== undefined) {
          submittedData = {
            ...submittedData,
            stampZeroImageBase64,
          };
        }
      }

      if (templateData.stampOneImage !== placeholderBanner) {
        let stampOneImageBase64 = getBase64DataForSubmit(
          templateData.stampOneImage
        );
        if (stampOneImageBase64 !== undefined) {
          submittedData = {
            ...submittedData,
            stampOneImageBase64,
          };
        }
      }

      if (templateData.stampTwoImage !== placeholderBanner) {
        let stampTwoImageBase64 = getBase64DataForSubmit(
          templateData.stampTwoImage
        );
        if (stampTwoImageBase64 !== undefined) {
          submittedData = {
            ...submittedData,
            stampTwoImageBase64,
          };
        }
      }

      if (templateData.stampThreeImage !== placeholderBanner) {
        let stampThreeImageBase64 = getBase64DataForSubmit(
          templateData.stampThreeImage
        );
        if (stampThreeImageBase64 !== undefined) {
          submittedData = {
            ...submittedData,
            stampThreeImageBase64,
          };
        }
      }

      if (templateData.stampFourImage !== placeholderBanner) {
        let stampFourImageBase64 = getBase64DataForSubmit(
          templateData.stampFourImage
        );
        if (stampFourImageBase64 !== undefined) {
          submittedData = {
            ...submittedData,
            stampFourImageBase64,
          };
        }
      }

      if (templateData.stampFiveImage !== placeholderBanner) {
        let stampFiveImageBase64 = getBase64DataForSubmit(
          templateData.stampFiveImage
        );
        if (stampFiveImageBase64 !== undefined) {
          submittedData = {
            ...submittedData,
            stampFiveImageBase64,
          };
        }
      }

      if (templateData.stampSixImage !== placeholderBanner) {
        let stampSixImageBase64 = getBase64DataForSubmit(
          templateData.stampSixImage
        );
        if (stampSixImageBase64 !== undefined) {
          submittedData = {
            ...submittedData,
            stampSixImageBase64,
          };
        }
      }

      if (templateData.stampSevenImage !== placeholderBanner) {
        let stampSevenImageBase64 = getBase64DataForSubmit(
          templateData.stampSevenImage
        );
        if (stampSevenImageBase64 !== undefined) {
          submittedData = {
            ...submittedData,
            stampSevenImageBase64,
          };
        }
      }

      if (templateData.stampEightImage !== placeholderBanner) {
        let stampEightImageBase64 = getBase64DataForSubmit(
          templateData.stampEightImage
        );
        if (stampEightImageBase64 !== undefined) {
          submittedData = {
            ...submittedData,
            stampEightImageBase64,
          };
        }
      }

      if (templateData.stampNineImage !== placeholderBanner) {
        let stampNineImageBase64 = getBase64DataForSubmit(
          templateData.stampNineImage
        );
        if (stampNineImageBase64 !== undefined) {
          submittedData = {
            ...submittedData,
            stampNineImageBase64,
          };
        }
      }

      if (templateData.stampTenImage !== placeholderBanner) {
        let stampTenImageBase64 = getBase64DataForSubmit(
          templateData.stampTenImage
        );
        if (stampTenImageBase64 !== undefined) {
          submittedData = {
            ...submittedData,
            stampTenImageBase64,
          };
        }
      }

      if (templateData.stampElevenImage !== placeholderBanner) {
        let stampElevenImageBase64 = getBase64DataForSubmit(
          templateData.stampElevenImage
        );
        if (stampElevenImageBase64 !== undefined) {
          submittedData = {
            ...submittedData,
            stampElevenImageBase64,
          };
        }
      }

      if (templateData.stampTwelveImage !== placeholderBanner) {
        let stampTwelveImageBase64 = getBase64DataForSubmit(
          templateData.stampTwelveImage
        );
        if (stampTwelveImageBase64 !== undefined) {
          submittedData = {
            ...submittedData,
            stampTwelveImageBase64,
          };
        }
      }
    }

    return submittedData;
  };

  const handleDuplicate = async () => {
    try {
      setLoading(true);
      let duplicateData = prepareDataForSubmit();

      await authAxios.post(
        `passes/templates?base_template_uuid=${templateData.uuid}`,
        duplicateData
      );

      toast({
        render: ({ onClose }) => (
          <CustomToast
            status="success"
            title="Pass template duplicated successfully."
            description=""
            onClose={onClose}
          />
        ),
      });
      setLoading(false);
      setTimeout(() => {
        navigate('/pass-templates', { replace: true });
        window.location.reload();
      }, 1000);
    } catch (onError) {
      appsignal.sendError(onError);
      console.log(onError);
      const { message, code } = getErrorResponsePayload(onError);
      setLoading(false);
      code !== 401 &&
        toast({
          render: (props) => (
            <CustomToast
              status="error"
              title={message ? message : `Something went wrong`}
              description={!message && 'Please try again later'}
              onClose={props.onClose}
            />
          ),
        });
    }
  };

  return (
    <Tooltip
      label="Copy template"
      hasArrow
      placement="top"
      fontSize="12px"
      color={textTooltip}
      w="180px"
      borderRadius="6px"
      bg={bgTooltip}
      textAlign="center"
      p="10px"
      closeOnClick={false}
    >
      <button onClick={handleDuplicate}>
        {loading ? (
          <Spinner
            marginTop={1.5}
            width="15px"
            height="15px"
            thickness="2px"
            speed="0.65s"
            emptyColor="quinaryBackground"
            mx="auto"
          />
        ) : (
          <CustomDuplicateIcon />
        )}
      </button>
    </Tooltip>
  );
};

export default DuplicateButton;
